<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">견적관리</h2>
      </div>
      <div class="body_section">
        <EstimateListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :defaultStatusId="defaultStatusId"
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultDateId="defaultDateId"
          @onClickSearch="onClickSearch"
        />
      </div>

      <TableHead>
        <template v-slot:headLeft>
          <button
            v-if="isShowAccessBtn(menuId.ESTIMATE_WRITE)"
            type="button"
            class="btn_tertiary btn_medium"
            @click="onClickGoToWrite"
          >
            <span class="ico_purchase ico_cross_w" />견적요청등록
          </button>
        </template>
        <template v-slot:headRight>
          <SelectBoxPageRange
            :currentMaxRowCount.sync="size"
            @update:currentMaxRowCount="onChangeRowCount"
          />
        </template>
      </TableHead>
      <ListPage
        :list="dataList"
        :isHorizontalScroll="true"
        :scrollWidth="'1500px'"
        :currentPageIndex="currentPageIndex"
        :totalPageCount="totalPageCount"
        :maxRowCount="size"
        :maxPaginationCount="maxPaginationCount"
        @onChangePage="onChangePage"
      >
        <template v-slot:colgroup>
          <!-- 상태 -->
          <col style="width: 80px" />
          <!-- 구분 -->
          <col style="width: 80px" />
          <!-- 견적요청번호 -->
          <col style="width: 120px" />
          <!-- 회신업체 -->
          <col style="width: 80px" />
          <!-- 견적명 -->
          <col />
          <!-- 구매요청번호 -->
          <col style="width: 110px" />
          <!-- 구매요청명 -->
          <col style="width: 220px" />
          <!-- 품목코드 (1번째) -->
          <col style="width: 120px" />
          <!-- 품목명 -->
          <col style="width: 140px" />
          <!-- 견적요청자 -->
          <col style="width: 160px" />
          <!-- 구매담당자 -->
          <col style="width: 160px" />
        </template>
        <template v-slot:tr>
          <th>상태</th>
          <th>구분</th>
          <th>견적요청번호</th>
          <th>회신업체</th>
          <th>견적명</th>
          <th>구매요청번호</th>
          <th>구매요청명</th>
          <th>품목코드</th>
          <th>품목명</th>
          <th>견적요청자</th>
          <th>구매담당자</th>
        </template>
        <template v-slot:row="slotProps">
          <EstimateListLine
            :rowData="slotProps.rowData"
            @onClickPopEstimatePrint="onClickPopEstimatePrint"
          />
        </template>
        <template v-slot:emptyList>
          <tr>
            <td colspan="11" class="td_empty">견적이 없습니다.</td>
          </tr>
        </template>
      </ListPage>
    </div>

    <template v-slot:popup>
      <ExcelDonwloadPopup
        v-if="isPopExcel"
        :excelDataList="excelHeaderList"
        :checkedNames.sync="checkedNamesExcel"
        @onClickClose="onClickCloseExcel"
        @onClickDownload="onClickDownloadExcel"
      />
      <EstimatePrintPopup
        v-if="isPopEstimatePrint"
        :estimateNo="estimatePrint ? estimatePrint.quotationNum : null"
        @onClickClose="onClickClosePopEstimatePrint"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import ApiService from "@/services/ApiService";
import PageWithLayout from "@/components/layout/PageWithLayout";
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";
import EstimateListFilterBar from "@/components/admin/estimate/list/EstimateListFilterBar";

import EstimateListLine from "@/components/admin/estimate/list/EstimateListLine";
import EstimatePrintPopup from "@/components/admin/estimate/popup/EstimatePrintPopup";

import PageMixin from "@/mixins/PageMixin";
import EstimateListMixin from "@/mixins/estimate/EstimateListMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

import { mapState } from "vuex";
import { ESTIMATE_LIST_ACTION } from "@/store";
import excelKey from "@/constants/excelKey";
import { ONE_WEEK, YESTERDAY, ALL } from "@/constants/dates";

import { stringToDate, dateToString } from "@/utils/dateUtils";

export default {
  components: {
    PageWithLayout,
    ListPage,
    TableHead,
    SelectBoxPageRange,
    ExcelDonwloadPopup,
    EstimateListFilterBar,
    EstimateListLine,
    EstimatePrintPopup,
    ApiService,
  },
  mixins: [PageMixin, EstimateListMixin],
  data() {
    return {
      API_PATH: this.$apiPath.ESTIMATE,
      defaultStatusId: "0",
      keywordList: [
        { id: "title", code: "title", desc: "견적요청명" },
        { id: "purchaseManagerName", code: "purchaseManagerName", desc: "구매사담당자" },
        { id: "reqName", code: "reqName", desc: "견적요청작성자" },
      ],
      defaultKeywordSelectedId: "title",
      defaultDates: [this.moment().day(-6), this.moment()],
      estimateItemList: null,
      isPopEstimatePrint: false,
      estimatePrint: null,
      estimateStatusList: [],
    };
  },

  computed: {
    statusList() {
      return this.estimateStatusList;
    },
  },
  created() {
    this.getStatus();
  },
  beforeMount() {
    const params = this.checkUrlQuery();
    // this.$store.dispatch(ESTIMATE_LIST_ACTION).then(() => {
    this.getDataInit(params);
    // });

    // this.excelKey = excelKey.ESTIMATE_PRUCHASE;
  },
  methods: {
    async getStatus() {
      const path = `${this.$apiPath.ESTIMATE_STATUS}`;
      const res = await ApiService.shared.getData(path);
      const { code, data, text } = res;
      this.estimateStatusList = data;
    },
    resultCallback(result) {},

    onClickGoToWrite() {
      this.$router.push(this.$routerPath.ESTIMATE_WRITE);
    },
    onClickPopEstimatePrint(item) {
      this.estimatePrint = item;
      // this.printItemData.title = item.estimateTitle;
      this.isPopEstimatePrint = true;
    },
    onClickClosePopEstimatePrint() {
      this.isPopEstimatePrint = false;
    },
    getFilterbarParams(objData) {
      const { status, startDate, endDate, keywordSelectedId, keywordInputText } = objData;

      let params = {};

      if (status) params.status = status;

      if (startDate && endDate) {
        params["startDate"] = startDate;
        params["endDate"] = endDate;
      }

      if (keywordSelectedId && keywordInputText) params[keywordSelectedId] = keywordInputText;

      return params;
    },
  },
};
</script>
