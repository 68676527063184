<template>
  <ListRow>
    <td>
      <span :class="['badge_state', badgeType]">{{ rowData.statusName }}</span>
    </td>
    <td>{{ rowData.upEstimateNum ? "재견적" : "-" }}</td>
    <td>{{ rowData.estimateNum }}</td>
    <td>
      <template v-if="rowData.resCount > 0">
        <span
          v-click-outside="onClickDialougeClose"
          style="cursor: pointer; text-decoration: underline"
          @click="onClickDialougeOpen"
        >{{ rowData.resCount }}</span>
        <Dialouge :isToggle="isToggle">
          <template v-slot:dialogueHead>
            <span class="txt_dialogue" />
          </template>
          <template v-if="estimateList" v-slot:dialogueBody>
            <span v-for="(item, idx) in estimateList" :key="idx">
              <strong :class="'tit_dialogue_strong'" @click="onClickPopEstimatePrint(item)">
                {{ item.companyName }}
              </strong>
              <button
                v-if="item.status !== 'S'"
                v-show="!selectCompany"
                type="button"
                class="btn_fourthly btn_small"
                @click="onClickSave(item, idx)"
              >
                선정
              </button>
              <span v-else type="button" class="tertiary btn_small">선정완료</span>
            </span>
          </template>
        </Dialouge>
      </template>
      <template v-else>
        <span>-</span>
      </template>
    </td>
    <td class="td_ellip" :data-ellip="rowData.title">
      <router-link
        :to="toPath"
        :class="'link_subject'"
        :data-ellip="rowData.title"
        v-html="rowData.title"
      />
    </td>
    <td>
      <button
        :class="['link_subject']"
        @click="$windowOpen(`${$routerPath.PURCHASE_VIEW}/${rowData.requestCid}`)"
      >
        {{ rowData.requestCid }}
      </button>
    </td>
    <td>
      {{ rowData.purchaseRequestTitle }}
    </td>
    <td>
      <template v-if="computedItemDisplayCode !== '-'">
        <button
          :class="['link_subject']"
          @click="$windowOpen(`${$routerPath.ITEM_VIEW}/${rowData.estimateItemList[0].itemCode}`)"
          v-html="computedItemDisplayCode"
        />
      </template>
      <template v-else> - </template>
    </td>
    <td class="align_left" v-html="computedItemName"></td>
    <td>{{ `${rowData.reqName} (${rowData.reqAccountId})` }}</td>
    <td>
      {{ `${rowData.purchaseManagerName} (${rowData.purchaseManagerAccountId})` }}
    </td>
  </ListRow>
</template>
<script>
import ListRow from "@/components/shared/ListRow";
import Dialouge from "@/components/common/dialouge/Dialouge.vue";

// import DialougeEstimate from '@/components/admin/estimate/popup/DialougeEstimate';

import { makePathWithQuery } from "@/utils/urlUtils";
import ListLineMixin from "@/mixins/ListLineMixin";
import ApiService from "@/services/ApiService";
import CommGnbVue from "../../../../_approval/components/common/CommGnb.vue";

export default {
  name: "EstimateListLine",
  components: {
    ListRow,
    Dialouge,
    // DialougeEstimate,
  },
  mixins: [ListLineMixin],
  data() {
    return {
      isToggle: false,
      estimateList: Object,
      selectCompany: false,
    };
  },
  computed: {
    toPath() {
      // 임시 저장 상태이면 수정페이지로, 완료 상태면 상세페이지로.
      // 구매사/공급사 구분이 필요하다면 처리.
      const { status, estimateNum } = this.rowData;

      // 임시 저장일 때만 수정 가능.
      const canModify = status === "T";

      const path = canModify ? this.$routerPath.ESTIMATE_MODIFY : this.$routerPath.ESTIMATE_VIEW;

      const ret = makePathWithQuery(path, estimateNum, this.$route.query);
      return ret;
    },
    badgeType() {
      //       0: {codeId: "0", codeName: "전체"}
      // 1: {codeId: "T", codeName: "임시저장"}
      // 2: {codeId: "S", codeName: "발송완료"}

      switch (this.rowData.statusCode) {
        case "T":
          return ""; // 임시저장
        case "S":
          return "badge_blue"; // 발송완료
      }
      return "";
    },
    computedItemDisplayCode() {
      const { estimateItemList } = this.rowData;
      const { displayCode } = this.rowData.estimateItemList[0];
      let ret = "";
      if (!displayCode) {
        ret = "-";
      } else {
        const displayCodeList = estimateItemList.reduce((acc, ele, index) => {
          const { displayCode } = ele;
          if (displayCode) {
            acc.push(displayCode);
          }
          return acc;
        }, []);
        const dropList = _.drop(displayCodeList);
        if (dropList.length > 0) {
          ret = `${displayCode} <strong data-ellip="${dropList.join("\n")}">(${
            dropList.length
          })</strong>`;
        } else {
          ret = `${displayCode}`;
        }
      }
      return ret;
    },
    computedItemName() {
      const { estimateItemList } = this.rowData;
      const { itemName } = this.rowData.estimateItemList[0];
      let ret = "";
      if (!itemName) {
        ret = "-";
      } else {
        const itemNameList = estimateItemList.reduce((acc, ele, index) => {
          const { itemName } = ele;
          if (itemName) {
            acc.push(itemName);
          }
          return acc;
        }, []);
        const dropList = _.drop(itemNameList);
        if (dropList.length > 0) {
          ret = `${itemName} <strong data-ellip="${dropList.join("\n")}">(${
            dropList.length
          })</strong>`;
        } else {
          ret = `${itemName}`;
        }
      }
      return ret;
    },
  },
  mounted() {},
  methods: {
    onClickDialougeOpen(e) {
      this.estimateGetData();
      this.isToggle = true;
    },
    onClickDialougeClose() {
      if (!this.isToggle) {
        return;
      }
      this.isToggle = false;
    },

    async estimateGetData() {
      const path = `${this.$apiPath.QUOTATION}?type=list&estimateNum=${this.rowData.estimateNum}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.estimateList = data;

      this.checkCompanySelection(data);
    },
    checkCompanySelection(data) {
      data.forEach((quotationInfoArr) => {
        if (quotationInfoArr.status === "S") {
          return (this.selectCompany = true);
        }
      });
    },
    onClickPopEstimatePrint(item) {
      // 정보에 구매사담당자 포함하겠다.
      // item.prchsrMngrNm = this.rowData.prchsrMngrNm;

      this.$emit("onClickPopEstimatePrint", item);
    },
    async onClickSave(item, idx) {
      const path = `${this.$apiPath.QUOTATION}/${item.quotationNum}/selection`;
      const result = await ApiService.shared.postData(path);
      const { code, message } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
        return;
      }
      //       const onComplete = (response) => {
      //         const { code, message } = response.data;
      //         if (code !== '200') {
      //           this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
      //           return;
      //         }
      //         this.goToList();
      //       };
      //       const { statusCode } = this.itemData;
      //       if (this.isModifyMode) {
      //         http.put(path, obj).then(onComplete);
      //       } else {
      //         http.post(path, obj).then(onComplete);
      //       }
      //       if (this.isModifyMode) {
      //         const { id } = this.$route.params;
      //         path = `${this.$apiPath.QUOTATION}/${id}?temp=${isTemp}`;
      //         const result = await ApiService.shared.putData(path, obj);
      //         const { code, message } = result;
      //         if (code !== '200') {
      //           this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
      //           return;
      //         }
      //       } else {
      //         path = `${this.$apiPath.QUOTATION}?temp=${isTemp}`;
      //         const result = await ApiService.shared.postData(path, obj);
      //         const { code, message } = result;
      //         if (code !== '200') {
      //           this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
      //           return;
      //         }
      //       }
      //       this.goToList();
    },
  },
};
</script>
<style lang="scss" scoped>
.tit_dialogue_strong {
  cursor: pointer;
  text-decoration: underline;
  width: 120px;
  display: inline-block;
  margin-top: 6px;
}

.tertiary {
  border-radius: 2px;
  background-color: #5551ce;
  color: #fff;
}
</style>
