<template>
  <div id="mFeature">
    <!-- GNB 상단 영역 확장 -->
    <!-- <gnb-extend-top /> -->
    <div class="box_gnb" role="navigation">
      <h2 id="kakaoGnb" class="screen_out">메인 메뉴</h2>
      <!-- 메뉴 목록 -->
      <gnb-menu />
      <!-- // 메뉴 목록 -->
    </div>
    <!-- <div class="foot_gnb">
      <button class="btn_gnb" @click="delegatePopup"><span class="ico_account ico_delegate"></span>대결지정하기</button>
    </div> -->
    <!-- GNB 하단 영역 확장 -->
    <!-- <gnb-extend-bottom /> -->
  </div>
  <!-- // mFeature -->
</template>

<script>
import GnbMenu from "../gnb/GnbMenu.vue";
import GnbExtendTop from "../gnb/GnbExtendTop.vue";
import GnbExtendBottom from "../gnb/GnbExtendBottom.vue";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";

export default {
  name: "CommGnb",
  components: {
    GnbMenu,
    GnbExtendTop,
    GnbExtendBottom,
  },
  mixins: [CommLayerMixin],
  methods: {
    delegatePopup(type) {
      this.isOpen = false;
      let layer = {};
      layer.header = "대결 지정";
      layer.type = "popup";
      layer.componentFile = "header/PopDelegate.vue";
      layer.style = "width:864px";
      if (type === "update") {
        layer.props = { curDelegate: { ...this.delegate } };
      } else {
        layer.props = { curDelegate: {} };
      }
      this._showLayer(layer, this.selectDelegate);
    },
  },
};
</script>
<style scoped>
#mFeature {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 240px;
  border-right: 1px solid #e4e4e4;
  background: #fff;
  box-sizing: border-box;
}

.box_gnb {
  position: relative;
  border-top: 1px solid #e4e4e4;
}

.box_gnb:first-child {
  margin-top: 56px;
  border-top: 0 none;
}
.box_gnb:first-child .list_gnb {
  padding-top: 0;
}

/* .box_gnb:after{
  position:absolute;bottom:-1px;left:0;right:0;height:41px;
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(248,248,248,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(248,248,248,1) 100%);
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(248,248,248,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f8f8f8',GradientType=0 );
  content:''
} */

.foot_gnb {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 32px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.8) 16%,
    rgba(255, 255, 255, 1) 33%
  );
}
.foot_gnb .btn_gnb {
  display: block;
  padding: 7px 16px;
  font-size: 12px;
  line-height: 18px;
  color: #555;
}
.foot_gnb .btn_gnb .ico_delegate {
  margin: 3px 4px 3px 0;
}
</style>
